<template>
  <div style="padding-bottom: 100px">
    <top-fixed></top-fixed>
    <div class="body_box">
      <div style="text-align: center; font-size: 18px; font-weight: 700">
        {{ $t("hj38") }}
      </div>
      <div class="body_mian">
        <div
          v-show="!show"
          class="qna-item"
          style="
            display: flex;
            margin: 20px 0px;
            background: #f4f4f4;
            padding: 0.5rem;
            border-radius: 10px;
          "
          v-for="(item, index) in helpData"
          :key="index"
          @click="showDetail(item)"
        >
          <div class="content-image" style="border-radius: 50%">
            <img
              :src="item.imgurl"
              alt=""
              style="
                width: 64px;
                height: 64px;

                margin-right: 20px;
              "
            />
          </div>
          <div class="content-body">
            <div
              class="title"
              style="
                color: #000000;
                font-weight: 700;
                line-height: 24px;
                font-size: 0.6rem;
              "
            >
              {{ item.title }}
            </div>
            <div
              class="subtitle"
              v-html="item.content"
              style="height: 24px; overflow: hidden; font-size: 12px"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopFixed from "../components/TopFixed.vue";
import { user_notice } from '../http/api'
export default {
  data () {
    return {
      helpData: [],
      show: false,
      showData: ''
    }
  },
  components: { TopFixed },
  created () {
    this.dataInit()
  },
  methods: {
    dataInit () {
      user_notice().then(res => {
        if (res.data) {
          this.helpData = res.data;
        }
      })
    },
    showDetail (value) {
      this.$router.push({
        path: "/NoticeDetail",
        query: {
          item: encodeURIComponent(JSON.stringify(value))
        }
      }
      )
    }
  }
}
</script>

<style  scoped lang="scss">
.body_box {
  background: #fff;
  position: relative;
  top: -5px;
  border-radius: 10px;
  padding: 20px;
}
.body_mian {
  padding: 10px 20px 10px 20px;
  margin-top: 20px;
  border-radius: 10px;
}
.subtitle :first-child {
  color: rgb(191, 191, 191);
}
.helpNotic {
  /deep/ p {
    background: #f4f4f4;
    border: 0.5px solid #dddddd;
    border-radius: 9px;
    box-shadow: 0px 1.5px 3px 0px rgba(0, 0, 0, 0.08) inset;
    margin: 0.6rem;
    padding: 0.6rem 2%;
    width: 95%;
  }
}
</style>